const host = "https://api.onlycoms.com"

const generate = (e) => {
  e.preventDefault();
  const form = document.getElementById("prompt-form")
  const formData = new FormData(form)
  const payload = Object.fromEntries(formData.entries())

  const promptCt = document.getElementById("prompt-container")
  promptCt.classList.add("loading")

  const prompt = document.getElementById("prompt")
  prompt.disabled = true
  prompt.classList.add("loading")

  const submitBtn = document.getElementById("submit-prompt")
  submitBtn.disabled = true
  submitBtn.innerText = "Starting search..."
  submitBtn.classList.add("loading")

  const loading = document.getElementById("loading-indicator")
  loading.classList.remove("hidden")

  document.getElementById("loading-indicator-text").innerText = "Searching for domain names..."
  const step = 1
  const progress = document.getElementById("progress")
  progress.style.width = "0%"

  document.getElementById("few-results").classList.add("hidden")

  const progressInterval = setInterval(() => {
    const val = parseFloat(progress.style.width.replace("%", "")) || 0
    progress.style.width = `${val + step}%`
    if (val >= 100) {
      clearInterval(progressInterval)
    }
  }, 100)

  setTimeout(() => {
    prompt.disabled = false
    prompt.classList.remove("loading")
    submitBtn.disabled = false
    submitBtn.classList.remove("loading")
  }, 1000)

  setTimeout(() => {
    document.getElementById("loading-indicator-text").innerText = "Checking domain availability..."
  }, 2000)

  setTimeout(() => {
    document.getElementById("loading-indicator-text").innerText = "Wrapping up..."
  }, 6000)

  fetch(`${host}/domains`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload),
  })
    .then(res => res.json())
    .then(data => {
      if (data.success) {
        const results = document.getElementById("results")
        const resultsContainer = document.getElementById("results-container")
        for (const domain of data.domains) {
          const card = document.createElement("a")
          card.setAttribute("href", `#${domain.domain}`)
          card.classList.add("card")
          const dm = document.createElement("p")
          dm.appendChild(document.createTextNode(domain.domain.replace(".com", "")))
          const dotcom = document.createElement("span")
          dotcom.appendChild(document.createTextNode(".com"))
          dm.appendChild(dotcom)
          const hv = document.createElement("em")
          hv.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" /></svg>'
          hv.appendChild(document.createTextNode("Register"))
          card.appendChild(dm)
          card.appendChild(hv)
          results.appendChild(card)
          card.addEventListener("click", (e) => {
            window.open(domain.url, "_blank")
          })
        }
        if (data.domains.length < 8) {
          document.getElementById("few-results").classList.remove("hidden")
        }
        loading.classList.add("hidden")
        resultsContainer.classList.remove("hidden")
        results.classList.remove("opacity-0")
      }
    })
}

window.onload = () => {
  document.getElementById("random-idea").addEventListener("click", (e) => {
    document.getElementById("random-idea").classList.add("loading")
    fetch(`${host}/idea`, {
      method: "POST"
    })
      .then((response) => response.json())
      .then((data) => {
        document.getElementById("random-idea").classList.remove("loading")
        if (data.success) {
          document.getElementById("prompt").value = data.idea;
        }
      })
  })
  document.getElementById("prompt-form").addEventListener("submit", (e) => {
    generate(e)
  })
  document.getElementById("generate-more").addEventListener("click", (e) => {
    generate(e)
  })
}